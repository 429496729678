import * as React from 'react';

type GameCardProps = {
	children: React.ReactNode;
};

type GameCardClickableProps = {
	onClick: () => void;
} & GameCardProps;

interface GameCardNamespace {
	Information: React.FC<GameCardProps>;
	Banner: React.FC<GameCardProps>;
	Title: React.FC<GameCardProps>;
	Provider: React.FC<GameCardProps>;
}

const GameCard: React.FC<GameCardClickableProps> & GameCardNamespace = ({ children, onClick }) => {
	return (
		<button className="flex flex-col" onClick={onClick} aria-label="Play Game">
			{children}
		</button>
	);
};

GameCard.Banner = ({ children }: GameCardProps) => {
	return <div className="h-28 cursor-pointer rounded-xl">{children}</div>;
};

GameCard.Information = ({ children }: GameCardProps) => {
	return <div className="flex flex-col items-start justify-start gap-y-1">{children}</div>;
};

GameCard.Title = ({ children }: GameCardProps) => {
	return <div className="line-clamp-1 pt-2 text-left text-sm font-semibold text-white">{children}</div>;
};

GameCard.Provider = ({ children }: GameCardProps) => {
	return <div className="text-xs font-medium text-brandblue-100">{children}</div>;
};

export default GameCard;
